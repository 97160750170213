.Layout {
    max-width: 1100px;
    margin: 10px auto;

}


.Body {
    /* margin: 1rem auto; */
    margin-top: 2rem;
    max-width: '3000px'; 
    padding: 0 0.5rem;
}

.Footer {
    /* background-color: #00384D; */
    color:  #00384D;
    font-size: 1rem;
    letter-spacing: 1px;
    padding: 10px 0 15px 0;
}

@media only screen and (min-width: 768px) {
    .Body {
        padding: 0 2rem;
        margin-top: 56px;
    
    }
}

@media only screen and (max-width: 480px) {
    .Layout {
        width: 100%;
        height: 100%;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }
}
