.Title {
    font-weight: bold;
    font-size: 3.3rem;
    color: #00384D;
    letter-spacing: 1px;
}

.PlusColor {
    color: #7ED956;
}

.SubtitleWhite {
    font-weight: bold;
    font-size: 2rem;
    color: white;
}

.Subtitle {
    font-weight: bold;
    font-size: 2rem;
    color: #00384D;
}

.Superscript {
    vertical-align: super;
    
}

.Paragraph {
    font-size: 1.4rem;
    color: #00384D;
    text-align: justify;
    padding-top: 50px;
}