.Home {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.Discover {
    background-color: #ecfaef;
    padding: 100px 50px;
    border-radius: 5px;
}

.DiscoverImg {
    width: 500px;
}

.Title {
    font-weight: bold;
    font-size: 3.3rem;
    color: black;
    letter-spacing: 1px;
}

.PlusColor {
    color: #84CE25;
    font-weight: 1000;
}

.SubtitleWhite {
    font-weight: bold;
    font-size: 2rem;
    color: white;
}

.Subtitle {
    font-weight: bold;
    font-size: 2rem;
    color: #00384D;
}

.Superscript {
    vertical-align: super;
    
}

.Paragraph {
    font-size: 1.4rem;
    color: #00384D;
    text-align: justify;
    padding-top: 50px;
}

.Feature {
    max-width: 250px;
    padding: 10px;
    margin: auto;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 220px; */
}



@media only screen and (max-width: 480px) {
    .Paragraph {
        font-size: 3vh;
    }
}





























































/* ORIGINAL DESIGN  */


/* .Heading {
    color: white;
    font-size: 2rem;
    text-align: left;
    font-weight: bold;
}

.ImageContainer{
    width: 100%;
    text-align: left;
}

.ImageLogo{
    text-align: left;
}
.OutterBox{

    margin-left: 10%;
    margin-right: 10%;
    margin-top: 3%;
    display: flex;
    align-items: center;
    gap: 10%;
    flex-wrap: nowrap;
    

}

.Title{
    text-align: left;
    margin-bottom: 10px;
}

.Text{
    color: white;
    text-align: justify;
}
.InnerBox{
    justify-content: center;
}

.Link{
    display: block;
    text-align: left;
    color: white;
    text-decoration: underline;
}
.LinkSmallDiv{
    display: block;
    text-align: left;
    color: white;
    text-decoration: none;
    position: fixed;
    bottom: 0;
    padding-bottom: 20px;
}

.SmallLink{
    text-decoration: none!important;
    color: white;
    font-size: 0.8rem;

}


.SpaceBetweenLink{
    margin-left: 5px;
    margin-right: 5px;
}

@media only screen and (max-width: 800px) {
    
    .OutterBox{
        flex-wrap: wrap;
    }
} */