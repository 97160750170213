/* .Contact {
    width: 100%;
} */

.Title {
    font-weight: bold;
    font-size: 3.3rem;
    color: #00384D;
    letter-spacing: 1px;
}

.PlusColor {
    color: #7ED956;
}

.Subtitle {
    font-weight: bold;
    font-size: 2rem;
    color: #00384D;
}

.Superscript {
    vertical-align: super;
    
}

.Button {
    width: 180px;
}

.PlusColorBackground {
    background-color: #7ED956;
    color: white;

}


@media only screen and (max-width: 480px) {
    .Subtitle {
        font-size: 5vw;
    }
}
