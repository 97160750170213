.App {
  text-align: center;

 
  
}



/* 
.LinkSmallDiv{
  display: block;
  text-align: left;
  color: white;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  padding-bottom: 20px;
  margin-left: 10%;
}


.Link{
  display: block;
  text-align: left;
  color: white;
  text-decoration: underline;
}

.SmallLink{
  text-decoration: none!important;
  color: white;
  font-size: 0.8rem;

} */