.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    /* overflow: hidden; */
    /* white-space: nowrap; */
}

.NavigationItem a {
    color: #00384D;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a span {
    color: #00384D;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: #34b3c9;
}


.Clickable:hover {
    border-top:4px solid #7ED956;
    color: black;
    cursor: pointer;
}

.Info {
    color: rgb(189, 189, 189);  
    border: 1px solid lightgrey;
    padding: 6px;
    border-radius: 4px;
}

@media (min-width: 849px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
        font-weight: bold;
        font-size: 1.1rem;
    }
    
    .NavigationItem a {
        color: #3591b2;
        height: 100%;
        padding: 16px 10px;
        border-top: 4px solid transparent;
    }

    .NavigationItem span {
        /* color: white; */
        height: 100%;
        padding: 16px 10px;
        border-top: 4px solid transparent;
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        /* background-color: #096070; */
        /* border-top:4px solid #40a4c8; */
        border-top:4px solid #7ED956;
        color: black;
    }

    .Clickable {
        color: #3591b2;
    }


    .Info {
        color: rgb(189, 189, 189);  
        border: none;
        padding: 0;
        border-radius: 0px;
    }    
    

}

